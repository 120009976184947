<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    :isExtended="true"
    @submitFilters="GetAllWallets"
  >
    <template #title> Wallet States </template>
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #extend>
      <div
        class="w-100 d-flex flex-column flex-md-row align-items-center justify-content-between"
      >
        <b-card bg-variant="primary" text-variant="white">
          <span>
            Total Blockchain Credits :
            {{ numChecker(parseFloat(totalBlockchainCredits)) }} $
          </span>
        </b-card>

        <b-card bg-variant="primary" text-variant="white">
          <span>
            Totlal Cold Wallet Credit :
            {{ numChecker(parseFloat(totlalColdWalletCredit)) }} $
          </span>
        </b-card>
      </div>
    </template>
    <template #filters>
      <b-form-group label="Count" label-for="count">
        <v-select
          id="count"
          v-model="walletsFilters.count"
          :options="perPageOptions"
          :clearable="false"
        />
      </b-form-group>
    </template>
    <custom-table
      :pageNumber="walletsFilters.pageNumber"
      :count="walletsFilters.count"
      :totalCount="totalCount"
      :tableItems="wallets"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #userName="data">
        <b-media vertical-align="center">
          <b-link
            :to="{
              name: 'apps-users-edit',
              params: { id: data.item.userId },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.userName }}
          </b-link>
          <small class="text-muted">@{{ data.item.userId }}</small>
        </b-media>
      </template>

      <template #totalCredit="data">
        <p>{{ numChecker(parseFloat(data.item.totalCredit)) }} $</p>
      </template>

      <template #coldWallet="data">
        <p>{{ numChecker(parseFloat(data.item.coldWallet)) }} $</p>
      </template>

      <template #blockChainCredit="data">
        <p>{{ numChecker(parseFloat(data.item.blockChainCredit)) }} $</p>
      </template>
    </custom-table>
  </page-wrapper>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BModal,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { WalletGetTotalWalletsRequest } from "@/libs/Api/Wallet";
import Helper from "@/libs/Helper";
import { getUserData } from "@/auth/utils";
import axiosIns from "@/libs/axios";
import { saveAs } from "file-saver";

export default {
  title: "Financials",
  name: "Financials",
  data() {
    return {
      wallets: null,
      isLoading: false,
      walletsFilters: {
        pageNumber: 1,
        count: 10,
      },
      totalCount: null,
      totalBlockchainCredits: null,
      totlalColdWalletCredit: null,
      baseURL: Helper.baseUrl + "media/gallery/profile/",
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: "userName",
          label: "User",
        },
        {
          key: "totalCredit",
          label: "Total Credit",
        },
        {
          key: "coldWallet",
          label: "Cold Wallet",
        },
        {
          key: "blockChainCredit",
          label: "BlockChain Credit",
        },
        {
          key: "walletAddress",
          label: "Wallet Address",
        },
      ],
    };
  },
  async created() {
    await this.GetAllWallets();
  },
  watch: {
    searchCommand: function () {
      this.GetAllWallets();
    },
    currentPage: function () {
      this.GetAllWallets();
    },
    'walletsFilters.count': function () {
      this.walletsFilters.pageNumber = 1;
      this.GetAllWallets();
    },
  },
  methods: {
    changePage(pageNumber) {
      this.walletsFilters.pageNumber = pageNumber;
      this.GetAllWallets();
    },
    async GetAllWallets() {
      try {
        this.isLoading = true;
        let _this = this;
        _this.isLoading = true;
        let walletGetTotalWalletsRequest = new WalletGetTotalWalletsRequest(
          _this
        );
        walletGetTotalWalletsRequest.setParams(_this.walletsFilters);
        await walletGetTotalWalletsRequest.fetch(
          function (content) {
            _this.wallets = content.wallets;
            _this.totalCount = content.walletsCount;
            _this.totlalColdWalletCredit = content.totlalColdWalletCredit;
            _this.totalBlockchainCredits = content.totalBlockchainCredits;
            _this.isLoading = false;
          },
          function (error) {
            console.log(error);
            _this.isLoading = false;
          }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns
        .post(`Wallet/GetTotalWalletExportExcel`, null, {
          responseType: "blob",
          Authorization: userToken,
        })
        .then((resp) => {
          _this.overlay = false;
          saveAs(resp.data, "financial.xlsx");
        })
        .catch((error) => {
          _this.overlay = false;
          console.log(error);
        });
    },
    numChecker(content) {
      if (content.toString().split(".")[1]?.length > 8) {
        return (content =
          content.toString().split(".")[0] +
          "." +
          content.toString().split(".")[1].substr(0, 8));
      } else return content;
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
    PageWrapper,
    CustomTable,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
</script>

<style scoped></style>
