<template>
  <page-wrapper
      :isLoading="isLoading"
      :hasFilters="true"
      @submitFilters="getUsers"
  >
    <template #title> Tatum accounts </template>
    <template #filters>
      <search-input
          @enter="getUsers"
          v-model="usersListFilters.search"
          inputId="SearchFilter"
          inputLabel="Search By User"
          inputPlaceholder="s y"
      ></search-input>
      <b-form-group label="Count" label-for="count">
        <v-select
            id="count"
            v-model="usersListFilters.count"
            :options="perPageOptions"
            :clearable="false"
        />
      </b-form-group>
    </template>
    <custom-table
        v-if="users"
        :pageNumber="usersListFilters.pageNumber"
        :count="usersListFilters.count"
        :totalCount="totalCount"
        :tableItems="users"
        :tableColumns="myTableColumns"
        @changePage="changePage"
    >
      <template #name="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
                size="45"
                :src="baseURL + data.item.selfieFileData"
                :variant="`success`"
                :to="{
                name: 'apps-users-edit',
                params: { id: data.item.userId },
              }"
            />
          </template>
          <b-link class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name }} {{ data.item.familyName }}
          </b-link>
          <small class="text-muted">@{{ data.item.userId }}</small>
        </b-media>
      </template>
      <template #transfer="data">
        <b-link
            @click="showTransferModal(data.item.userId)"
        >
          <feather-icon icon="DollarSignIcon" size="20" />
        </b-link>
      </template>
    </custom-table>

    <TransferTatumModal
        :isLoading="isLoading"
        :okCallback="transfer"
    ></TransferTatumModal>
  </page-wrapper>
</template>

<script>
import { BMedia, BAvatar, BLink, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import {UsersGetAllHasTronRequest, UsersGetAllRequest} from "@/libs/Api/Users";
import Helper from "@/libs/Helper";

export default {
  title: "Tatum accounts",
  data() {
    return {
      isLoading: false,
      users: null,
      baseURL: Helper.baseUrl,
      selectedUserId:null,
      usersListFilters: {
        pageNumber: 1,
        count: 10,
        search: null,
        perState: null,
      },
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        { key: "name", label: "User" },
        { key: "userName", label: "User Name" },
        { key: "email", label: "email" },
        { key: "transfer", label: "Transfer" },
      ],
    };
  },
  async created() {
    await this.getUsers();
  },
  methods: {
    async transfer(param) {
      try {
        if (!param) return;
        this.isLoading = true;
        let _this = this;
        let blockchainTrasferBetweenInternalTatumAccountRequest = new BlockchainTrasferBetweenInternalTatumAccountRequest(_this);
        blockchainTrasferBetweenInternalTatumAccountRequest.setRequestParam({
          recieverUserId:_this.selectedUserId,
          amount: param,
        });
        await blockchainTrasferBetweenInternalTatumAccountRequest.fetch((res) => {
          if (res.isSuccess) {
            _this.selectedUserId = null;
            _this.$bvModal.hide("transfer-tatum");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Confirmed!",
                icon: "CheckIcon",
                variant: "success",
                text: "Operation successful",
              },
            });
            _this.getUsers();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    showTransferModal(param){
      this.selectedUserId = param;
      this.$bvModal.show("transfer-tatum");
    },
    changePage(pageNumber) {
      this.usersListFilters.pageNumber = pageNumber;
      this.getUsers();
    },
    refreshData() {
      this.getUsers();
    },
    async getUsers() {
      try {
        this.isLoading = true;
        let _this = this;

        let usersGetAllHasTronRequest = new UsersGetAllHasTronRequest(_this);
        usersGetAllHasTronRequest.setParams(_this.usersListFilters);
        await usersGetAllHasTronRequest.fetch(
            function (content) {
              _this.users = content.data.users;
              _this.totalCount = content.data.usersCount;
            },
            function (error) {
              console.log(error);
            }
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch: {
    count: function (nv, ov) {
      this.usersListFilters.pageNumber = 1;
      this.getUsers();
    },
  },
  computed: {
    count() {
      return this.usersListFilters.count;
    },
  },
  components: {
    TransferTatumModal,
    CreateNewFakeUsersModal,
    BMedia,
    BAvatar,
    BLink,
    vSelect,
    PageWrapper,
    SearchInput,
    BFormGroup,
    CustomTable,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CreateNewFakeUsersModal from "@/views/components/User/CreateNewFakeUsersModal.vue";
import {AccountCreateFakeUser} from "@/libs/Api/Account";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TransferTatumModal from "@/views/components/User/TeransferTatumModal.vue";
import {BlockchainTrasferBetweenInternalTatumAccountRequest} from "@/libs/Api/BlockChain";
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
