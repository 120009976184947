<template>
  <page-wrapper
      :isLoading="isLoading"
      :hasFilters="true"
      @submitFilters="GetAllCandles"
  >
    <template #title>Candles List</template>
    <template #titleBtn>
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class=""
          variant="success"
          @click="downloadFile"
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <search-input
          @enter="GetAllCandles"
          v-model="searchCommand"
          inputId="SearchCommand"
          inputLabel="Search By Candle Markets"
          inputPlaceholder="BTCUSDT"
      ></search-input>
      <div class="w-auto">
        <b-form-group label="Count" label-for="perPage">
          <v-select
              id="perPage"
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector w-100"
          />
        </b-form-group>
      </div>
    </template>
    <custom-table
        v-if="candles"
        customIconClass="text-primary"
        :pageNumber="currentPage"
        :count="perPage"
        :totalCount="totalCount"
        :tableItems="candles"
        :tableColumns="myTableColumns"
        @changePage="changePage"
    >
      <template #actions="data">
        <b-button
            type="button"
            variant="transparent"
            size="sm"
            @click="redirectToCandleDetails(data.item)"
        >
          <feather-icon icon="EditIcon" class="text-primary" size="20"/>
        </b-button>
      </template>
    </custom-table>
  </page-wrapper>
</template>

<script>
import {BButton, BFormGroup} from "bootstrap-vue";
import vSelect from "vue-select";
import {CandleGetAllRequest} from "@/libs/Api/Candle";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
import {saveAs} from "file-saver";
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";

export default {
  title: "Candles List",
  name: "CandlesList",
  data() {
    return {
      candles: null,
      totalCount: null,
      isLoading: false,
      showDeleteModal: false,
      currentPage: 1,
      deleteItem: null,
      perPage: 10,
      CategoryName: "",
      TicketType: 0,
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: "candleId",
          label: "Candle id",
        },
        {
          key: "market",
          label: "Market",
        },
        {
          key: "open",
          label: "Open",
        },
        {
          key: "closed",
          label: "Closed",
        },
        {
          key: "startTime",
          label: "Start Time",
          formatter: (value) => {
            return `${value.slice(0, 10)} - ${value.slice(11, 19)}`;
          },
        },
        {
          key: "endTime",
          label: "End Time",
          formatter: (value) => {
            return `${value.slice(0, 10)} - ${value.slice(11, 19)}`;
          },
        },
        {
          key: "actions",
          label: "Candle Details",
        },
        // {
        //   key: 'Detail',
        //   label: 'show'
        // },
      ],
      searchCommand: "",
      pageNumber: 1,
      count: 10,
      SelectedTicket: null,
    };
  },
  async created() {
    await this.GetAllCandles();
  },
  watch: {
    searchCommand: function () {
      this.GetAllCandles();
    },
    currentPage: function () {
      this.GetAllCandles();
    },
    perPage: function () {
      this.GetAllCandles();
    },
  },
  methods: {
    redirectToCandleDetails(candle) {
      this.$router.push(`/apps-candles/${candle.candleId}`);
    },
    isRenderingDetailsButton() {
      return true;
    },
    changePage(pageNumber) {
      this.currentPage = pageNumber;
    },
    async GetAllCandles() {
      let _this = this;
      _this.isLoading = true;
      let candleGetAllRequest = new CandleGetAllRequest(_this);
      let data = {
        pageNumber: _this.currentPage,
        count: _this.perPage,
        searchCommand: _this.searchCommand,
      };
      candleGetAllRequest.setParams(data);
      await candleGetAllRequest.fetch(
          function (content) {
            _this.candles = content.candles;
            _this.totalCount = content.counts;
            _this.isLoading = false;
          },
          function (error) {
            _this.isLoading = false;
            console.log(error);
          }
      );
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns
          .post(`Candle/GetAllExportExcel`, null, {
            responseType: "blob",
            Authorization: userToken,
            params: {
              searchCommand: _this.searchCommand
            }
          })
          .then((resp) => {
            _this.overlay = false;
            saveAs(resp.data, "candles.xlsx");
          })
          .catch((error) => {
            _this.overlay = false;
            console.log(error);
          });
    },
  },
  components: {
    PageWrapper,
    SearchInput,
    CustomTable,
    BFormGroup,
    BButton,
    vSelect,
  },
};
</script>

<style scoped></style>
