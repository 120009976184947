<template>
  <page-wrapper
    :isLoading="isLoading"
    :hasFilters="true"
    @submitFilters="getReports"
  >

    <!--  Excel Modal  -->
    <b-modal
        id="modalExcel"
        centered
        cancelTitle="Close"
        title="Download Excel File"
        ok-title="Download"
        @ok="downloadFile"
    >
      <b-row>
        <b-col cols="12">
          <custom-date-picker
              width="w-100"
              @getSelectedDate="setFromDateExcelFilter"
              inputLabel="From Date"
              inputPlaceholder="2023-12-06"
              inputId="fromDateExcel"
          ></custom-date-picker>
        </b-col>
        <b-col cols="12">
          <custom-date-picker
              width="w-100"
              @getSelectedDate="setToDateExcelFilter"
              inputLabel="To Date"
              inputPlaceholder="2023-12-06"
              inputId="toDateExcel"
          ></custom-date-picker>
        </b-col>
        <b-col cols="12">
          <search-input
              width="w-100"
              v-model.trim="excelListFilters.groupId"
              inputId="groupId"
              inputLabel="Search By group id"
              inputPlaceholder=""
          ></search-input>
        </b-col>
      </b-row>
    </b-modal>
    <!--  Excel Modal  -->

    <template #title
      >Fake users reports (Total Credits : {{ totalCredit }} Tron)</template
    >
    <template #titleBtn>
      <b-button
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        class=""
        variant="success"
        @click="renderCreateModal"
      >
        + Add fake users
      </b-button>
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class=""
          variant="primary"
          v-b-modal:modalExcel
      >
        Download Excel File
      </b-button>
    </template>
    <template #filters>
      <custom-date-picker
        @getSelectedDate="setFromDateFilter"
        inputLabel="From Date"
        inputPlaceholder="2023-12-06"
        inputId="fromDate"
      ></custom-date-picker>
      <custom-date-picker
        @getSelectedDate="setToDateFilter"
        inputLabel="To Date"
        inputPlaceholder="2023-12-06"
        inputId="toDate"
      ></custom-date-picker>
      <search-input
        v-model.trim="requestsListFilters.groupId"
        inputId="groupId"
        inputLabel="Search By group id"
        inputPlaceholder=""
      ></search-input>
    </template>
    <custom-table
      v-if="requestsList"
      customIcon="CheckSquareIcon"
      customIconClass="text-success"
      :pageNumber="requestsListFilters.pageNumber"
      :count="requestsListFilters.count"
      :totalCount="totalCount"
      :tableItems="requestsList"
      :tableColumns="myTableColumns"
      @changePage="changePage"
    >
      <template #fakeUserLevel="data">
        <span v-if="data.item.fakeUserLevel">{{ data.item.fakeUserLevel.name ? data.item.fakeUserLevel.name : "-" }}</span>
        <span v-else>-</span>
      </template>
    </custom-table>

    <create-new-fake-users-modal
      :isLoading="isLoading"
      :okCallback="createNewFakeUsers"
    ></create-new-fake-users-modal>
  </page-wrapper>
</template>

<script>
import { FakeUserGetReportRequest } from "@/libs/Api/FakeUsers";
import {saveAs} from "file-saver";

export default {
  title: "Fake users reports",
  mounted() {
    this.getReports();
  },
  data() {
    return {
      isLoading: false,
      totalCredit: "-",
      requestsListFilters: {
        pageNumber: 1,
        count: 10,
        fromDate: "",
        toDate: "",
        groupId: "",
      },
      excelListFilters: {
        fromDate: "",
        toDate: "",
        groupId: "",
      },
      totalCount: null,
      requestsList: null,
      myTableColumns: [
        {
          key: "groupId",
          label: "group id",
        },
        {
          key: "userName",
          label: "userName",
          formatter: (value) => value.trim() || "-",
        },
        {
          key: "createDate",
          label: "Request Create Date",
          formatter: (value) => new Date(value).toLocaleDateString("en-US"),
        },
        {
          key: "fakeUserLevel",
          label: "User Level",
        },
        {
          key: "walletCredit",
          label: "Wallet Credit",
          formatter: (value) => {
            let priceValue = 0;
            if (value && +value > 0) {
              priceValue = value;
            }
            return `${priceValue} $`;
          },
        },
      ],
      tempRequest: null,
    };
  },
  methods: {
    async getReports() {
      try {
        this.isLoading = true;
        let _this = this;
        let fakeUserGetReportRequest = new FakeUserGetReportRequest(_this);
        fakeUserGetReportRequest.setParams(_this.requestsListFilters);
        await fakeUserGetReportRequest.fetch((res) => {
          _this.totalCount = res.data.totalCount;
          _this.totalCredit = res.data.totalCredit;
          _this.requestsList = res.data.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createNewFakeUsers(param) {
      try {
        this.isLoading = true;
        let _this = this;
        let accountCreateFakeUser = new AccountCreateFakeUser(_this);
        accountCreateFakeUser.setRequestParam({
          count: param.count,
          countryId: param.countryId,
          type: 0,
          fakeUserLevelId: param.fakeUserLevelId,
          minValue: param.minValue,
          maxValue: param.maxValue,
        });
        await accountCreateFakeUser.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("create-new-fake-users");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Confirmed!",
                icon: "CheckIcon",
                variant: "success",
                text: "Operation successful",
              },
            });
            _this.getReports();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadFile() {
      let _this = this;
      _this.overlay = true;
      let userToken = getUserData().data.token;

      await axiosIns
          .post(`FakeUser/GetExcel`, null, {
            responseType: "blob",
            Authorization: userToken,
            params: _this.excelListFilters
          })
          .then((resp) => {
            _this.overlay = false;
            saveAs(resp.data, "fakeUsers.xlsx");
          })
          .catch((error) => {
            _this.overlay = false;
            console.log(error);
          });
    },
    setToDateFilter(date) {
      this.requestsListFilters.toDate = date;
    },
    setFromDateFilter(date) {
      this.requestsListFilters.fromDate = date;
    },
    setToDateExcelFilter(date) {
      this.excelListFilters.toDate = date;
    },
    setFromDateExcelFilter(date) {
      this.excelListFilters.fromDate = date;
    },
    renderCreateModal() {
      this.$bvModal.show("create-new-fake-users");
    },
    changePage(pageNumber) {
      this.requestsListFilters.pageNumber = pageNumber;
      this.getReports();
    },
  },
  components: {
    BFormGroup, BCol, BModal, BFormInput,
    CreateNewFakeUsersModal,
    CreateNewBonusModal,
    PageWrapper,
    CustomDatePicker,
    CashoutConfirmationModal,
    SearchInput,
    CustomTable,
    BButton,
    BBadge,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomDatePicker from "@/views/components/Utilities/CustomDatePicker.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CashoutConfirmationModal from "@/views/components/Referral/CashoutConfirmationModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {BButton, BBadge, BFormInput, BModal, BCol, BFormGroup} from "bootstrap-vue";
import CreateNewBonusModal from "@/views/components/Bonus/CreateNewBonusModal.vue";
import CreateNewFakeUsersModal from "@/views/components/User/CreateNewFakeUsersModal.vue";
import { AccountCreateFakeUser } from "@/libs/Api/Account";
import {getUserData} from "@/auth/utils";
import axiosIns from "@/libs/axios";
</script>
