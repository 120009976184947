<template>
  <b-form-group
    class="d-inline-block filters-width mr-md-2"
    :class="[width]"
    :label="inputLabel"
    :label-for="inputId"
  >
    <b-form-input
      trim
      class="d-inline-block"
      :id="inputId"
      :value="value"
      :placeholder="inputPlaceholder"
      @input="$emit('input', $event)"
      @keydown.enter="emitEnterKeydown"
    />
  </b-form-group>
</template>

<script>
export default {
  emits: ["enter"],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    inputId: {
      type: String,
      required: false,
      default: "searchCommand",
    },
    value: {
      type: [String, Number, null],
      default: null,
    },
    inputLabel: {
      type: [String, null],
      default: "Search",
    },
    inputPlaceholder: {
      type: [String, null],
      default: "Search...",
    },
    width:{
      type:String,
      default:'w-24',
      required:false
    }
  },
  methods: {
    emitEnterKeydown() {
      this.$emit("enter");
    },
  },
  components: {
    BFormGroup,
    BFormInput,
  },
};
import { BFormGroup, BFormInput } from "bootstrap-vue";
</script>
<style scoped>
.w-24 {
  width: 23% !important;
}
.ignore-box-sizing * {
  box-sizing: border-box;
}
@media only screen and (max-width: 468px) {
  .filters-width {
    width: 100% !important;
  }
}
</style>
